import React from "react";

import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const Barchart = () => {
  const data = [
    { name: "Group A", value: 200 },
    { name: "Group B", value: 200 },
    { name: "Group C", value: 200 },
    { name: "Group D", value: 200 },
    { name: "Group D", value: 200 },
    { name: "Group D", value: 200 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = [
    "#F857A6",
    "#BCCCBF",
    "#BCCCBF",
    "#BCCCBF",
    "#BCCCBF",
    "#F857A6",
    "#F857A6",
    "#BCCCBF",
  ];

  const COLORS2 = [
    "#24FE41",
    "#24FE41",
    "#BCCCBF",
    "#BCCCBF",
    "#BCCCBF",
    "#24FE41",
    "#24FE41",
    "#24FE41",
    "#24FE41",
    "#24FE41",
  ];

  return (
    <div class="card-body">
      <h5 class="card-title">OUR USERS</h5>
      <p class="card-text opacity-0 invisible">80,231,567,977.00</p>

      <div className="d-flex justify-content-center align-items-center barchart-first">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <span className="pastuser">Past Users</span>
          <span className="percent">Down to 30%</span>
          <img src="..\assets\images\joinimage\arrow-down.png" />
        </div>
        <div
          style={{ paddingLeft: "30px" }}
          className="graph-text"
          data-aos="chart-ltr-animation"
        >
          <PieChart width={80} height={80}>
            {/* <Tooltip /> */}
            <Pie
              data={data}
              cx={30}
              cy={35}
              innerRadius={20}
              outerRadius={27}
              // fill="#8884d8"
              paddingAngle={4}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <div className="graph-percentage">40%</div>
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <div data-aos="chart-ltr-animation" className="graph-text">
          <PieChart width={90} height={90}>
            {/* <Tooltip /> */}
            <Pie
              data={data}
              cx={33}
              cy={40}
              innerRadius={30}
              outerRadius={37}
              fill="#8884d8"
              paddingAngle={4}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS2[index % COLORS2.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <div className="graph-percentage-lower">70%</div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center barchart-first-margin">
          <img src="..\assets\images\joinimage\up-arrow.png" />
          <span className="percent">Upto to 30%</span>
          <span className="pastuser">Current Users</span>
        </div>
      </div>
    </div>
  );
};

export default Barchart;
