import React, { useEffect } from "react";
import "./nfts.css";

import AOS from "aos";
import "aos/dist/aos.css";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CountUp from "react-countup";

const Nfts = () => {
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: true,
  };

  useEffect(() => {
    AOS.init({
      offset: 250,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 10,
      once: false,
    });
    AOS.refresh();
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="nft-section">
      <div className="nfts-bg ">
        <div className="">
          <div className="nfts-title">
            <img src="..\assets\images\nftsimage\nfts-text.webp" />
            <p>Hi There! Wana Ride Just Get Your Ride !</p>
          </div>
          <div className="nfts-only-laptop-section">
            <Slider
              style={{ width: "90%", margin: "0 auto" }}
              {...sliderSettings}
            >
              {/* 1st slider */}

              <div className="slider-container d-flex ">
                <div className="mobile-responsive">
                  <div
                    data-aos="fade-down"
                    data-aos-delay="1500"
                    data-aos-offset="300"
                    className="icon-container desktop-margin"
                  >
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>
                        {" "}
                        <CountUp end={16} duration={4} /> mph
                      </span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div
                    data-aos="fade-right"
                    data-aos-delay="1500"
                    data-aos-offset="260"
                    className="icon-container desktop-middle-icon"
                  >
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={16} duration={4} /> mph
                      </span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1500"
                    data-aos-offset="220"
                    className="icon-container desktop-margin"
                  >
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={1000} duration={4} /> ccc
                      </span>
                      <span>Suspension</span>
                    </div>
                  </div>
                </div>

                <img
                  className="image-main-car"
                  data-aos="fade-zoom-in"
                  data-aos-delay="1500"
                  data-aos-offset="220"
                  src="..\assets\images\nftsimage\nfts-car1.webp"
                />

                <div className="mobile-responsive">
                  <div
                    data-aos="fade-down"
                    data-aos-delay="1500"
                    className="icon-container desktop-margin2"
                  >
                    <div className="speed-container">
                      <span>
                        <CountUp end={2000} duration={4} /> cc
                      </span>
                      <span>Power</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>
                  <div
                    data-aos="fade-left"
                    data-aos-delay="1500"
                    className="icon-container desktop-middle-icon-2"
                  >
                    <div className="speed-container">
                      <span>cat "A"</span>
                      <span>Tyre</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div
                    data-aos="fade-up"
                    data-aos-delay="1500"
                    className="icon-container desktop-margin2"
                  >
                    <div className="speed-container">
                      <span>
                        <CountUp end={2022} duration={4} />{" "}
                      </span>
                      <span>Model</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>

              {/* 2nd slider */}

              <div className="slider-container d-flex">
                <div className="mobile-responsive">
                  <div className="icon-container desktop-margin">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>18 mph</span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div className="icon-container desktop-middle-icon">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>18 mph</span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div className="icon-container desktop-margin">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>1200ccc</span>
                      <span>Suspension</span>
                    </div>
                  </div>
                </div>

                <img
                  className="image-main-car"
                  src="..\assets\images\nftsimage\Group 1539.webp"
                />

                <div className="mobile-responsive">
                  <div className="icon-container desktop-margin2">
                    <div className="speed-container">
                      <span>18 mph</span>
                      <span>Mileage</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>
                  <div className="icon-container desktop-middle-icon-2">
                    <div className="speed-container">
                      <span>18 mph</span>
                      <span>Fuel</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div className="icon-container desktop-margin2">
                    <div className="speed-container">
                      <span>1200ccc</span>
                      <span>Suspension</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>

              {/* 3rd slider */}

              <div className="slider-container d-flex">
                <div className="mobile-responsive">
                  <div className="icon-container desktop-margin">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>21 mph</span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div className="icon-container desktop-middle-icon">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>21 mph</span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div className="icon-container desktop-margin">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>1400ccc</span>
                      <span>Suspension</span>
                    </div>
                  </div>
                </div>

                <img
                  className="image-main-car"
                  src="..\assets\images\nftsimage\Group 1538.webp"
                />

                <div className="mobile-responsive">
                  <div className="icon-container desktop-margin2">
                    <div className="speed-container">
                      <span>21 mph</span>
                      <span>Mileage</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>
                  <div className="icon-container desktop-middle-icon-2">
                    <div className="speed-container">
                      <span>21 mph</span>
                      <span>Fuel</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div className="icon-container desktop-margin2">
                    <div className="speed-container">
                      <span>1400ccc</span>
                      <span>Suspension</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>
            </Slider>

            <div className="nfts-animated-bg-2">
              <img
                data-aos="nft-animation-bg"
                src="..\assets\images\nftsimage\nfts-bg.webp"
              />
            </div>
            <div className="container nfts-animated-bg">
              <img
                data-aos="slide-up"
                className="img-fluid nfts-text-image"
                src="..\assets\images\nftsimage\nfts-animated-bg.webp"
              ></img>
            </div>
          </div>

          <div className="nfts-only-mobile-section text-white">
            <Slider {...settings}>
              <div className="slider-container">
                <div className="mt-5">
                  <img
                    className="image-main-car"
                    src="..\assets\images\nftsimage\nfts-car1.webp"
                  />
                </div>
                <div className="mobile-responsive row">
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>
                        {" "}
                        <CountUp end={16} duration={4} /> mph
                      </span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>cat "A"</span>
                      <span>Tyre</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={1000} duration={4} /> ccc
                      </span>
                      <span>Suspension</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2000} duration={4} /> cc
                      </span>
                      <span>Power</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>

                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={16} duration={4} /> mph
                      </span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2022} duration={4} />{" "}
                      </span>
                      <span>Model</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>
              <div className="slider-container">
                <div className="mt-5">
                  <img
                    className="image-main-car"
                    src="..\assets\images\nftsimage\Group 1539.webp"
                  />
                </div>
                <div className="mobile-responsive row">
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>
                        {" "}
                        <CountUp end={18} duration={4} /> mph
                      </span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>cat "B"</span>
                      <span>Tyre</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={1200} duration={4} /> ccc
                      </span>
                      <span>Suspension</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2500} duration={4} /> cc
                      </span>
                      <span>Power</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>

                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={18} duration={4} /> mph
                      </span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2023} duration={4} />{" "}
                      </span>
                      <span>Model</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>
              <div className="slider-container">
                <div className="mt-5">
                  <img
                    className="image-main-car"
                    src="..\assets\images\nftsimage\Group 1538.webp"
                  />
                </div>
                <div className="mobile-responsive row">
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1451.webp"
                    />
                    <div className="speed-container">
                      <span>
                        {" "}
                        <CountUp end={21} duration={4} /> mph
                      </span>
                      <span>Mileage</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>cat "C"</span>
                      <span>Tyre</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1453.webp"
                    />
                  </div>
                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1449.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={1400} duration={4} /> ccc
                      </span>
                      <span>Suspension</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2700} duration={4} /> cc
                      </span>
                      <span>Power</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1454.webp"
                    />
                  </div>

                  <div className="icon-container  col-6  ">
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1450.webp"
                    />
                    <div className="speed-container">
                      <span>
                        <CountUp end={21} duration={4} /> mph
                      </span>
                      <span>Fuel</span>
                    </div>
                  </div>
                  <div className="icon-container col-6 ">
                    <div className="speed-container">
                      <span>
                        <CountUp end={2018} duration={4} />{" "}
                      </span>
                      <span>Model</span>
                    </div>
                    <img
                      className="img-fluid nft-icon"
                      src="..\assets\images\nftsimage\Group1452.webp"
                    />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nfts;
