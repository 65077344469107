import React, { useEffect } from "react";
import Barchart from "./Barchart";
import "./chart.css";
import Piechart from "./Piechart";
import Pielastchart from "./Pielastchart";

import AOS from "aos";
import "aos/dist/aos.css";

const Chart = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-sine",
      once: false,
    });
    AOS.refresh();
  }, []);
  return (
    <section>
      <div className="chart-wrapper" id="analytics">
        <div className="chart-bg text-white">
          <div data-aos="new-animation-chart" className="chart-title">
            <img
              className="img-fluid"
              src="..\assets\images\joinimage\join-today.png"
              alt="join-today"
            />
          </div>

          <div className="chart-container">
            <div className="row d-flex chart-card-container">
              <div className="col-lg-2 col-md-4">
                <h2 className="join-chart-heading">D-STREAK USERS</h2>
                <div class="card join-chart-card first-one">
                  <Barchart />
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <h2 className="join-chart-heading">WORLD DISTANCE</h2>
                <div class="card join-chart-card second-one">
                  <Piechart />
                  {/* <div className="second-card-overlay">hello</div> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <h2 className="join-chart-heading">D-STREAK EARN</h2>
                <div class="card join-chart-card third-one">
                  <Pielastchart />
                </div>
              </div>
              <div className="col-lg-6 ">
                <div
                  data-aos="new-animation-chart"
                  className="mt-4 green-car green-car-mobile-bg"
                >
                  <img
                    className="img-fluid green-car-image"
                    src="..\assets\images\joinimage\green-car.png"
                    alt="car"
                  />
                  <div className="car-smoke">
                    <img src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/car-smoke.gif" />
                  </div>
                  <div className="car-smoke-2">
                    <img src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/car-smoke.gif" />
                  </div>
                  <div className="car-smoke-3">
                    <img src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/car-smoke.gif" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="textbg-animation-chart"
          data-aos-duration="1000"
          data-aos-delay="50"
          className="animated-chart-text"
        >
          <img src="..\assets\images\joinimage\join-text-bg-latest.png"></img>
        </div>
      </div>
    </section>
  );
};

export default Chart;
