import React, { useEffect, useState } from "react";
import "./newfooter.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Newfooter() {
  const initialValues = { email: "" };
  const [formvalues, setFormvalues] = useState(initialValues);
  const [error, setError] = useState({});
  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (values.email == "") {
      errors.email = "Please enter Email";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid Email Format";
    }

    setError(errors);
    return errors;
  };
  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormvalues({ ...formvalues, [name]: value });
    console.log(formvalues);
  };
  const handlesubmit = (e) => {
    const errors = validate(formvalues);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      toast.error(errors.email, { toastId: "error" });
      return;
    } else {
      setFormvalues(initialValues);
      setError({});
      toast.success("Successfully Data Submitted !", { toastId: "success" });
    }
  };
  return (
    <section className="footer-sec">
      <div className="footer-inner">
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formvalues.email}
          onChange={handlechange}
          data-aos="slide-down"
          data-aos-duration="1000"
        />
        <button
          onClick={handlesubmit}
          data-aos="slide-down"
          data-aos-duration="1000"
        >
          SUBSCRIBE
        </button>
        <div
          className="social-links"
          data-aos="slide-up"
          data-aos-duration="1000"
        >
          <a href="https://www.facebook.com/dstreakofficial/" target="_blank">
            <img src="/assets/images/footer/Vector.png" alt="" />
          </a>
          <a href="https://www.instagram.com/dstreak_/" target="_blank">
            <img src="/assets/images/footer/fooer-icon1.png" alt="" />
          </a>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2FStreakDrive"
            target="_blank"
          >
            <img src="/assets/images/footer/footer-icon3.png" alt="" />
          </a>
          <a href="https://discord.com/invite/d9yQV934hm" target="_blank">
            <img src="/assets/images/footer/footer-icon2.png" alt="" />
          </a>
          <a
            href="https://medium.com/@dstreak.io22"
            target="_blank"
            className="medium-icon"
          >
            <img src="/assets/images/footer/footer-icon4.png" alt="" />
          </a>
        </div>
        <p data-aos="slide-up" data-aos-duration="1000">
          COPYRIGHT D-STREAK LAB LTD. 2024 - ALL RIGHTS RESERVED <span>|</span>{" "}
          POWERED BY D-STREAK LAB.LTD
        </p>
      </div>
      <div className="footer-layer"></div>
    </section>
  );
}

export default Newfooter;
