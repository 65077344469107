import React from "react";
import "./intro.css";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Intro = () => {
  const showToastMessage = () => {
    toast.info("Comming Soon!", { toastId: "comming" });
  };
  //hello
  return (
    <section>
      <div className="intro-container d-flex align-items-center justify-content-center">
        <div className="d-flex flex-column gap-3 align-items-center justify-content-center wrapper">
          <img
            className="img-fluid"
            src="..\assets\images\intro\intro-logo.svg"
            alt="logo"
          />
          <p>
            D-streak is a decentralized NFT-based mobility app introducing a
            drive-to-earn model for its audience, which offers users the chance
            to make earnings simply through the activity of daily driving.
          </p>
          <Link to="home">LET’S RIDE</Link>
          <div className="mt-3">
            <a
              href="https://play.google.com/store/apps/details?id=com.drivestreak"
              target="_blank"
            >
              <img
                className="img-fluid"
                src="..\assets\images\intro\playstore.svg"
                alt="logo"
              />
            </a>

            <img
              className="ms-3 img-fluid"
              src="..\assets\images\intro\appstore-btn.svg"
              alt="logo"
              onClick={showToastMessage}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </section>
  );
};

export default Intro;
