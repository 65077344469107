import React, { useEffect } from "react";
import "./navbar.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Navbar = () => {
  const showToastMessage = () => {
    toast.info("Comming Soon!", { toastId: "comming" });
  };

  const closeNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarMenu = document.querySelector("#navbarSupportedContent");

    if (!navbarToggler.classList.contains("collapsed")) {
      navbarToggler.click();
    }
  };

  return (
    <div className="navbar-wrapper">
      <nav className="pt-4 navbar navbar-expand-lg navbar-dark bg-primary bg-transparent mb-5 ms-0">
        <div className="container-fluid ">
          <a
            data-aos="home-hero-ltr-animation"
            className="navbar-brand text-white"
            href="#"
            onClick={closeNavbar}
          >
            <div className="logo-wrapper">
              <a href="/home">
                <img
                  className="img-fluid"
                  src="..\assets\images\header\navlogo-2.webp"
                  alt="Logo"
                />
              </a>
            </div>
          </a>
          <button
            className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto me-4 px-3 mb-2 mb-lg-0 pt-3 ">
              <li className="nav-item d-flex flex-column pe-4 ">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="#"
                  onClick={closeNavbar}
                >
                  Home
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  href="#analytics"
                  onClick={closeNavbar}
                >
                  Analytics
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  href="#nft-section"
                  onClick={closeNavbar}
                >
                  NFT’S
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  href="#tokken-section"
                  onClick={closeNavbar}
                >
                  Tokens
                </a>
              </li>
              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  href="#roadmap-section"
                  onClick={closeNavbar}
                >
                  Roadmap
                </a>
              </li>

              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  href="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/d-streakpitchdeck.pdf"
                  onClick={closeNavbar}
                  style={{ cursor: "pointer" }}
                  target="_blank"
                >
                  Pitch Deck
                </a>
              </li>

              <li className="nav-item ">
                <a
                  className="nav-link text-white"
                  onClick={() => {
                    showToastMessage();
                    closeNavbar();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  MarketPlace
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ToastContainer />
    </div>
  );
};

export default Navbar;
