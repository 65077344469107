import React from "react";
import { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const Pielastchart = () => {
  // const [customWidth, setCustomWidth] = useState("40px");
  const data = [
    { name: "Group A", value: 300 },
    { name: "Group B", value: 300 },
  ];
  const COLORS = ["#F5A0CE", "#A9A9A9", "#F5A0CE"];

  const data2 = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 200 },
  ];
  const COLORS2 = ["#95C11F", "#A9A9A9"];
  // useEffect(() => {
  //   console.log(window.innerWidth);
  //   if (window.innerWidth == "123px") {
  //     setCustomWidth("50px");
  //   } else if (window.size == "456px") {
  //     setCustomWidth("100px");
  //   }
  // }, [window.innerWidth]);
  return (
    <div class="card-body">
      <h5 class="card-title">EARNINGS</h5>
      <p class="card-text invisible">$ 231,567,977</p>

      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center"></div>
      </div>

      <div className="d-flex flex-column  justify-content-center align-items-center lastmonth-wrapper">
        <span className="lastmonth invisible">Last Month : $ 189,678,456</span>
        <div className="mt-3 last-graph-text" data-aos="chart-rtl-animation">
          <PieChart width={90} height={50}>
            {/* <Tooltip /> */}
            <Pie
              data={data}
              cx={40}
              cy={40}
              startAngle={180}
              endAngle={0}
              innerRadius={27}
              outerRadius={43}
              fill="#8884d8"
              paddingAngle={1}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <div className="last-graph-text-percentage">40%</div>
        </div>
      </div>

      <div className=" d-flex flex-column  justify-content-center align-items-center pielastchart-lower">
        <span className="lastmonth invisible">
          Profit is 48% More than last Month
        </span>
        <div
          className="pielastchart-lower-space last-graph-text"
          data-aos="chart-rtl-animation"
        >
          <PieChart width={110} height={50}>
            {/* <Tooltip /> */}
            <Pie
              data={data2}
              cx={50}
              cy={50}
              startAngle={180}
              endAngle={0}
              innerRadius={40}
              outerRadius={54}
              // fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {data2.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS2[index % COLORS2.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <div className="last-bottom-graph-text-percentage">80%</div>
        </div>
      </div>
    </div>
  );
};

export default Pielastchart;
