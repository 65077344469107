import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/Home/Home";
import Intro from "./page/intro/Intro";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  // AOS.init({
  //   duration: 3200,
  //   delay: 30,
  //   once: false,
  // });
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
