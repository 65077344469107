import React, { useEffect } from "react";
import "./roadmap.css";

import AOS from "aos";
import "aos/dist/aos.css";

const Roadmap = () => {
  useEffect(() => {
    AOS.init({
      offset: 20,
      once: false,
    });
    AOS.refresh();
  }, []);

  return (
    <section id="roadmap-section">
      <div className="roadmap-bg mt-5 mb-5">
        <div className="roadmap-title" style={{ marginBottom: "70px" }}>
          <img src="..\assets\images\roadmap\Road map.png" />
          <span>Set Goals To Achieve Big !</span>
        </div>
        {/* data-aos="new-animation-roadmap-img-1" data-aos-delay="1500",data-aos="new-animation-roadmap-img-3" data-aos-delay="1500" */}
        <div className="roadmap-wrapper">
          <div className="roadmap-timeline-bg">
            <div>
              <img
                data-aos="new-animation-roadmap-img-1"
                data-aos-delay="1000"
                src="..\assets\images\roadmap\ist.webp"
              />
            </div>
            <div>
              <img
                data-aos="new-animation-roadmap-img-2"
                data-aos-delay="1000"
                src="..\assets\images\roadmap\second.webp"
              />
            </div>
            <div>
              <img
                data-aos="new-animation-roadmap-img-3"
                data-aos-delay="1000"
                src="..\assets\images\roadmap\third.webp"
              />
            </div>
          </div>
          <div
            data-aos="roadmap-locator-animation"
            data-aos-delay="100"
            data-aos-duration="1000"
            className="roadmap-locater roadmap-locater-mobile"
          >
            {/* <img
              className="img-fluid"
              src="..\assets\images\roadmap\bg-locater.png"
            /> */}
          </div>
          <div className="roadmap-locater">
            <img
              data-aos="roadmap-map-animation"
              className="img-fluid roadmap-locater-mobile"
              src="..\assets\images\roadmap\bg-map.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
