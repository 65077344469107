import React, { useState, useEffect } from "react";
import "./home.css";
import Navbar from "./navbar/Navbar";
import { motion } from "framer-motion";
import Nfts from "../nfts/Nfts";
import Footer from "../footer/Footer";
import Download from "../downloadnow/Download";
import Partners from "../partners/Partners";
import Team from "../Team/Team";
import Roadmap from "../roadmap/Roadmap";
import Tokken from "../tokken/Tokken";
import Chart from "../chart/Chart";

import AOS from "aos";
import "aos/dist/aos.css";
import Newfooter from "../newfooter/newfooter";
import Downloadnow from "../downloadnow/downloadnow";

const Home = () => {
  const [loading, setLoading] = useState(false);

  //useeffect for loader
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  //useeffect for car-image
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("carwithlight").src =
        "../../../assets/images/car-with-light.webp";
    }, 8000);
  }, []);

  //use effect for animation
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 10,
      once: false,
    });
    AOS.refresh();
  }, []);
  return (
    <section>
      {loading ? (
        <div className="loader-car-animation">
          <img src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/D Car_1.gif" />
        </div>
      ) : (
        <>
          <div className="home-container">
            <Navbar />
            <div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="smoke"
              >
                <video playsInline autoPlay muted loop>
                  <source
                    src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/videos/final-smoke-video.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 1 }}
                className="home-car-smoke"
              >
                <img src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/car-smoke.gif" />
              </motion.div>
              <div data-aos="slide-down" className="header-upper-bg">
                <img
                  className="img-fluid"
                  src="..\assets\images\header\header-upper-bg.webp"
                  alt="image"
                />
              </div>
              <div className="header-lower-bg">
                <img
                  className="img-fluid"
                  src="..\assets\images\header\header-lower-bg.webp"
                  alt="image"
                />
              </div>
            </div>
            <div
              data-aos="home-hero-ltr-animation"
              className="text d-flex flex-column gap-2"
              style={{ zIndex: "999999" }}
            >
              <a
                href="https://drive-streak.gitbook.io/welcome-to-d-streak-whitepaper/"
                target="_blank"
                style={{ width: "fit-content" }}
              >
                Explore More
              </a>
              <p>Everyone drives, only pros earn profits!</p>
            </div>
            {/* data-aos="home-hero-rtl-animation" */}
            <div className="car-img">
              <img
                id="carwithlight"
                src="..\assets\images\car-without-light.webp"
                alt="car"
              />
            </div>
          </div>
          <Chart />
          <Nfts />
          <Tokken />
          <Roadmap />
          {/* <Team /> */}
          {/* <Partners /> */}
          {/* <Download /> */}
          <Downloadnow />
          <Newfooter />
          {/* <Footer /> */}
        </>
      )}
    </section>
  );
};

export default Home;
