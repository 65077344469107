import React from "react";

import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Piechart = () => {
  const data = [
    { name: "Geeksforgeeks", students: 900 },
    { name: "Technical scripter", students: 600 },
    { name: "Geek-i-knack", students: 750 },
    { name: "Geek-o-mania", students: 840 },
    { name: "Geek-o-mania", students: 300 },
    { name: "Geek-o-mania", students: 800 },
    { name: "Geek-o-mania", students: 1000 },
  ];

  const COLORS = [
    "#95C11F",
    "#E5E930",
    "#F242F5",
    "#2FF4D1",
    "#FA4646",
    "#E67106",
    "#06E688",
  ];

  return (
    <div class="card-body">
      <h5 class="card-title">DSTEAK DISTANCE</h5>
      <p class="card-text">80,567,977.00(KM)</p>

      <div className="piechart-size-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart width={160} height={150} data={data}>
            {/* <Tooltip /> */}
            <Bar dataKey="students" fill="green">
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Piechart;
