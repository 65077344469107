import React, { useEffect, useState } from "react";
import "./tokken.css";

import AOS from "aos";
import "aos/dist/aos.css";

const Tokken = () => {
  const [showtokken, setShowtokken] = useState(false);

  // console.log(showtokken);

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 1500,
      easing: "ease-in-sine",
      once: false,
    });
    AOS.refresh();
  }, []);

  return (
    <section id="tokken-section" style={{ marginTop: "100px" }}>
      <div className="tokken-bg">
        <div className="tokken-title">
          <img src="..\assets\images\tokken\D-Streak Tokens.webp" />
        </div>

        {showtokken ? (
          <div>
            <div className="text-white  ">
              <div className="hover-text-wrapper hover-text-inner-wrapper">
                <div className="hover-text-l-f">
                  <div className="row">
                    <div className="col-lg-3 hover-text-image">
                      <img src="..\assets\images\tokken\Group 1530.webp" />
                      <div className="hover-text-left-hand">
                        <img src="..\assets\images\tokken\left-hand.webp" />
                      </div>
                    </div>
                    <div className="col-lg-9 hover-text-content">
                      <h3>RADEEM TOKKEN</h3>

                      <p>
                        The D-streak marketplace is supported by two tokens. One
                        of which is the DST token also known as the governance
                        token. This token is required for all purchases that
                        occur outside the app. If the user has amassed
                        sufficient rewards and wishes to run any transactions
                        outside the app, then they may convert the in-app token
                        into the DST token. Hence it is tradable on most
                        centralized exchange platforms like Binance. Both the
                        DRV and DST tokens can be swapped for one another.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="hover-text-r-f">
                  <div className="row">
                    <div className="col-lg-9 hover-text-content hover-text-content-r">
                      <h3>IN APP TOKKEN</h3>
                      <p>
                        The DRV is the ERC-20 fungible token which means it can
                        be exchanged. The DRV token serves as the in-app
                        currency which means it is the backbone of the app. It’s
                        necessary to run all purchases, buying, and selling that
                        occur within the app. To buy a new car, more fuel, or
                        engage in any transaction at the in-app marketplace the
                        user must have adequate DRV tokens. Since the DRV acts
                        as the utility token it can be swapped for the DST
                        token.
                      </p>
                    </div>
                    <div className="col-lg-3 hover-text-image">
                      <img
                        className="right-hand-image"
                        src="..\assets\images\tokken\Group 1529.webp"
                      />
                      <div className="hover-text-right-hand">
                        <img src="..\assets\images\tokken\right.webp" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tokken-animated-text-bg">
              <img src="..\assets\images\tokken\Group 1468.webp" />
            </div>
          </div>
        ) : (
          <div>
            <div
              data-aos="slide-up"
              data-aos-duration="1500"
              className="tokken-animated-bg"
            >
              <img src="..\assets\images\tokken\tokken-bg.webp" />
            </div>

            <div className="row container-fluid tokken-content">
              <div className="col-lg-6 col-md-6 col-12">
                <img
                  className="img-fluid dst"
                  src="..\assets\images\tokken\Group 1536 (2).webp"
                />
                <p data-aos="fade-right">Redeemed Token</p>
              </div>

              <div className="col-lg-6 col-md-6 col-12">
                <img
                  className="img-fluid dst"
                  src="..\assets\images\tokken\Group 1536.webp"
                />
                <p data-aos="fade-left">In App Token</p>
              </div>
            </div>
          </div>
        )}

        <div className="tokken-btn-container text-center">
          <button
            onClick={() => setShowtokken(!showtokken)}
            className="tokken-clickbtn"
          >
            Click Me
          </button>
        </div>
      </div>
    </section>
  );
};

export default Tokken;
