import React from "react";
import "./downloadnow.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Downloadnow() {
  const showToastMessage = () => {
    toast.info("Comming Soon!", { toastId: "comming" });
  };
  return (
    <section className="downloadnow-sec">
      <div className="download-inner">
        <h1>Download now</h1>
        <div className="download-btns">
          <a
            href="https://play.google.com/store/apps/details?id=com.drivestreak"
            target="_blank"
          >
            <img src="..\assets\images\intro\playstore.svg" alt="logo" />
          </a>
          <a onClick={showToastMessage}>
            <img
              src="..\assets\images\intro\appstore-btn.svg"
              alt="logo"
              style={{ cursor: "pointer" }}
            />
          </a>
          <img
            src="..\assets\images\downloadNow\DN-mobile.png"
            alt=""
            className="download-mobile"
          />
        </div>
      </div>
      <div className="download-layer">
        <img
          src="https://qazibucket-dest.s3.us-east-2.amazonaws.com/dStreak/images/giphy.gif"
          alt=""
        />
      </div>
    </section>
  );
}

export default Downloadnow;
